import TechprosVector from './../../images/blog/TechprosVector.jpeg'
import LiferayVector from './../../images/blog/LiferayVector.jpeg';

export const blogArticles = [
  {
    img: TechprosVector,
    title: 'Joining forces to become stronger: Introducing the Techpros & Vector partnership',
    intro: 'Trust is the foundation upon which the confidence in project deliverables and overall performance is built. It is a significant milestone that Techpros and Vector have proudly attained working hand in hand. \n \n \n',
    url: 'https://www.linkedin.com/pulse/joining-forces-become-stronger-introducing-techpros-vector'
  },
  {
    img: LiferayVector,
    title: 'In 2024, Vector Team takes a significant step forward by achieving the Enterprise Liferay partnership status in collaboration with Liferay.',
    intro: 'This partnership marks a commitment to expanding knowledge in development, enhancing customer relations, and undertaking projects from inception to delivery, aiming to provide even greater value to existing clients and partners.',
    url: 'https://www.linkedin.com/feed/update/urn:li:activity:7159112181005860864/'
  },
  {
    empty: true
  }
]